/* global $ */
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  components: {},
  props: {
    startAt: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  watch: {
    dimensions: {
      deep: true,
      handler(newFilter, oldFilter) {
        this.updateFilter();
      },
    },
    filtredBrands: {
      deep: true,
      handler(newFilter, oldFilter) {
        this.updateFilter();
      },
    },
    ssr: {
      deep: true,
      handler(newFilter, oldFilter) {
        this.updateFilter();
      },
    },
    offer: {
      deep: true,
      handler(newFilter, oldFilter) {
        this.updateFilter();
      },
    },
  },
  computed: {
    ...mapGetters('tirefilter', ['getDimensions', 'getFilter', 'getOffer', 'getSsr']),
    ...mapState('tirefilter', [
      'brands',
      'filtredBrands',
      'dimensions',
      'ssr',
      'offer',
      'total',
      'count',
    ]),
  },
  methods: {
    ...mapActions('tirefilter', [
      'setBrands',
      'updateBrands',
      'setFilterCount',
      'setDimensions',
      'setFiltredBrands',
    ]),

    matchFilter() {},
    updateFilter() {
      const brands = {};
      let total = 0;
      let count = 0;

      let offerCount = 0;
      let ssrCount = 0;

      $('.rimset .RimSets').each((i, el) => {
        const data = $(el).data();
        const { brand } = data;
        const tire = $(el).find('.tyrecon:first');
        const tireData = $(tire).data();
        const tireClasses = $(tire).attr('class');

        let visible = this.fitsFilterDimensions(tire);

        // Creates brand if it dosn't exist
        if (!brands[brand]) {
          brands[brand] = {
            count: 0,
            total: 0,
          };
        }

        if (this.getOffer.checked && !$(tire).hasClass('o')) {
          visible = false;
        }
        if (this.getSsr.checked && !$(tire).hasClass('rf')) {
          visible = false;
        }

        // Brands count are saved before other brand filtering is applied.
        brands[brand].total = brands[brand].total ? brands[brand].total + 1 : 1;
        if (visible) brands[brand].count = brands[brand].count ? brands[brand].count + 1 : 1;

        if (this.filtredBrands.indexOf(brand) === -1) {
          visible = false;
        }

        if (visible && $(tire).hasClass('o')) offerCount += 1;
        if (visible && $(tire).hasClass('rf')) ssrCount += 1;
        if (visible) count += 1;
        total += 1;

        $(tire).toggleClass('hidden', !visible);
      });

      this.setFilterCount({ total, count, ssrCount, offerCount });
      // this.setFilter({ filter: { brands } });
      if (typeof window.fetchTyreStockStatus === 'function') window.fetchTyreStockStatus();
      this.updateBrands(brands);
    },
    fitsFilterDimensions(tire) {
      if (
        (this.getDimensions().width === -1 || $(tire).hasClass(`b${this.getDimensions().width}`)) &&
        (this.getDimensions().profile === -1 ||
          $(tire).hasClass(`p${this.getDimensions().profile}`)) &&
        (this.getDimensions().inches === -1 || $(tire).hasClass(`t${this.getDimensions().inches}`))
      ) {
        return true;
      }

      return false;
    },
    parseFromDom() {
      const brands = {};

      $('.rimset .RimSets').each((i, el) => {
        const data = $(el).data();
        const { brand } = data;

        // Creates brand if it dosn't exist
        if (!brands[brand]) {
          const logoEl = $(el).find('.logo');
          let logoUrl = '';
          if (brand === 'BestBuy') {
            logoUrl = 'https://cdn.thg.dk/DAT/dom/wg/BestBuy30.png';
          } else if (logoEl) {
            const bgImage = logoEl.css('background-image');
            logoUrl = bgImage ? bgImage.split(/"/)[1] : '';
          }
          brands[brand] = {
            logo: logoUrl,
          };
        }
      });
      this.setBrands(brands);
    },
  },
  created() {},
  mounted() {
    this.parseFromDom();
    this.updateFilter();
  },
};
